/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from "react";
import axios from 'axios';
import Post from "./post"
import CreatePostModal from './createPostModal'
import "../styles/posts.css"

export default class Posts extends Component {
    constructor(props) {
        super(props);

        this.toggleModal = this.toggleModal.bind(this)

        this.state = {
            apiResponse: "", //Response from API calls
            responseArray: [], //Array of posts from API response
            show: false //Show createPostModal or not
        }
    }

    //Flips value of show. Changes visibility of the createPostModal
    toggleModal() {
        this.setState({
            show: !this.state.show
        });
    };

    componentWillMount() {
        //Retrieve all posts and update state
        axios
            .get("https://posttit-api.rahimi-misha3278.workers.dev/posts")
            .then((response) => {
                var postsArray = [];

                for (var key in response.data) {
                    postsArray.push(response.data[key])
                }

                this.setState({ apiResponse: (response.data), responseArray: (postsArray) });
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    render() {
        return (
            <div>
                <div className="justify-content-center mainHeader">
                    <div className="vertical-center">
                        <a href="#" className="pageTitle">Posttit</a>
                    </div>
                    <div className="button" onClick={this.toggleModal}>
                        Create Post
                    </div>
                </div>
                <div className="mainContent container">
                    {
                        //If there's no posts to display, show message, otherwise map each post to a Post component
                        this.state.responseArray.length === 0
                            ?
                            <div className="row justify-content-center">
                                <div className="col-10 col-md-8 post noPosts">
                                    <p>No posts availabe :(</p>
                                </div>
                            </div>
                            :
                            <div>
                                {
                                    this.state.responseArray.map((post) => (
                                        <div className="postContainer">
                                            <Post post={post} />
                                        </div>
                                    ))
                                }
                                <div className="row justify-content-center">
                                    <div className="endOfPosts col-10 col-md-8 endOfPosts post">
                                        <p>End of Posts</p>
                                    </div>
                                </div>
                            </div>
                    }
                    <CreatePostModal onClose={this.toggleModal} show={this.state.show} />
                </div>
            </div>
        );
    }
}