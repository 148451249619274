import React from "react";
import axios from 'axios';
import '../styles/createPostModal.css';


export default class Modal extends React.Component {
  constructor(props) {
    super(props);

    this.onChangeUsername = this.onChangeUsername.bind(this);
    this.onChangeTitle = this.onChangeTitle.bind(this);
    this.onChangeContent = this.onChangeContent.bind(this);
    this.onSubmit = this.onSubmit.bind(this);

    this.state = {
      username: "",
      title: "",
      content: "",
      apiError: false
    };
  }

  componentDidMount() {
    this.setState({apiError: false})
  }

  onChangeUsername(e) {
    this.setState({
      username: e.target.value,
    });
  }

  onChangeTitle(e) {
    this.setState({
      title: e.target.value,
    });
  }

  onChangeContent(e) {
    this.setState({
      content: e.target.value,
    });
  }

  onSubmit(e) {
    e.preventDefault();

    const newPost = {
      username: this.state.username,
      title: this.state.title,
      content: this.state.content,
      comments: []
    };

    axios
      .post('https://posttit-api.rahimi-misha3278.workers.dev/add', newPost, {
        headers: {
          withCredentials: true,
          credentials: 'include'
        }
      })
      .then((response) => {
        this.setState({
          apiError: false
        })

        this.setState({
          username: "",
          title: "",
          content: ""
        });

        window.location.reload()
      })
      .catch(() => {
        console.log('caught an error')

        this.setState({
          apiError: true
        })
      })
  }

  render() {
    if (!this.props.show) {
      return null;
    }

    return (
      <div className="createPostModal">
        <div className="mainContent">
          <div className="modalHeader">
            <div className="action">
              <div className="cancelButton" onClick={() => {
                this.setState({apiError: false})
                this.props.onClose()
              }}>X</div>
            </div>
            <h2 className="formHeader">Create a Post</h2>
            {
              this.state.apiError ? <p className="apiError">Unable to connect to server, please try again later</p> : ""
            }
          </div>
          <div className="content">
            <form onSubmit={this.onSubmit}>
              <div className="form-group">
                <label>Username: </label>
                <input
                  type="text"
                  className="form-control"
                  value={this.state.username}
                  onChange={this.onChangeUsername}
                  required
                />
              </div>
              <div className="form-group">
                <label>Post Title: </label>
                <input
                  type="text"
                  className="form-control"
                  value={this.state.title}
                  onChange={this.onChangeTitle}
                  required
                />
              </div>
              <div className="form-group">
                <label>Post Content: </label>
                <input
                  type="text"
                  className="form-control"
                  value={this.state.content}
                  onChange={this.onChangeContent}
                  required
                />
              </div>
              <div className="form-group buttonGroup">
                <input
                  type="submit"
                  value="Create Post"
                  className="button"
                />
              </div>
            </form>
          </div>
        </div>
      </div>
    )
  }
}

