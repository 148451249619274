import React, { Component } from "react";

import "../styles/comment.css"

export default class Comment extends Component {
    constructor(props) {
        super(props);

        this.state = {
            username: props.comment.username,
            comment: props.comment.comment
        };
    }

    render() {
        return (
            <div>
                <div className="commentContainer">
                    <div className="username">{this.state.username}</div>
                    <div className="comment">{this.state.comment}</div>
                </div>
            </div>
        );
    }
}