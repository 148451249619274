import './styles/App.css';
import Posts from "./components/posts"

const App = () => {
  return (
    <div className="App">
      <Posts />
    </div>
  );
};

export default App;