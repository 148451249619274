import React from "react";
import axios from 'axios';
import '../styles/addCommentModal.css';


export default class AddCommentModal extends React.Component {
    constructor(props) {
        super(props);

        this.onChangeCommentUsername = this.onChangeCommentUsername.bind(this);
        this.onChangeComment = this.onChangeComment.bind(this);
        this.onSubmit = this.onSubmit.bind(this);

        this.state = {
            postId: props.postId,
            commentUsername: "",
            comment: "",
            apiError: false
        };
    }

    componentDidMount() {
        this.setState({ apiError: false })
    }

    // These methods will update the state properties.
    onChangeCommentUsername(e) {
        this.setState({
            commentUsername: e.target.value,
        });
    }

    onChangeComment(e) {
        this.setState({
            comment: e.target.value,
        });
    }

    // This function will handle the submission.
    onSubmit(e) {
        e.preventDefault();

        const updateComment = {
            comment: this.state.comment,
            commentUsername: this.state.commentUsername,
            postId: this.state.postId
        };

        axios
            .post("https://posttit-api.rahimi-misha3278.workers.dev/addComment", updateComment)
            .then((response) => {
                this.setState({
                    apiError: false
                })

                this.setState({
                    commentUsername: "",
                    comment: ""
                });

                window.location.reload()
            })
            .catch(() => {
                console.log('caught an error')

                this.setState({
                    apiError: true
                })
            })
    }

    render() {
        if (!this.props.show) {
            return null;
        }

        return (
            <div className="addCommentModal">
                <div className="mainContent">
                    <div className="modalHeader">
                        <div className="action">
                            <div className="cancelButton" onClick={() => {
                                this.setState({ apiError: false })
                                this.props.onClose()
                            }}>X</div>
                        </div>
                        <h2 className="formHeader">Add a Comment</h2>
                        {
                            this.state.apiError ? <p className="apiError">Unable to connect to server, please try again later</p> : ""
                        }
                    </div>
                    <div className="content">
                        <form onSubmit={this.onSubmit}>
                            <div className="form-group">
                                <label>Your Username: </label>
                                <input
                                    type="text"
                                    className="form-control"
                                    value={this.state.commentUsername}
                                    onChange={this.onChangeCommentUsername}
                                    required
                                />
                            </div>
                            <div className="form-group">
                                <label>Comment: </label>
                                <input
                                    type="text"
                                    className="form-control"
                                    value={this.state.comment}
                                    onChange={this.onChangeComment}
                                    required
                                />
                            </div>
                            <div className="form-group buttonGroup">
                                <input
                                    type="submit"
                                    value="Add Comment"
                                    className="button"
                                />
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        )
    }
}

