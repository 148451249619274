import React, { Component } from "react";
import Comment from "./comment"
import AddCommentModal from "./addCommentModal"
import "../styles/post.css"

export default class Posts extends Component {
    constructor(props) {
        super(props);

        this.toggleComments = this.toggleComments.bind(this)
        this.toggleModal = this.toggleModal.bind(this)

        this.state = {
            postId: props.post.postId,
            username: props.post.username,
            title: props.post.title,
            content: props.post.content,
            showComments: false,
            comments: props.post.comments ? props.post.comments : [],
            showCommentModal: false
        };
    }

    toggleComments() {
        this.setState({ showComments: !this.state.showComments })
    }

    toggleModal() {
        this.setState({ showCommentModal: !this.state.showCommentModal })
    }

    render() {
        return (
            <div>
                <div className="row justify-content-center">
                    <div className="col-10 col-md-8 post">
                        <h2>{this.state.title}</h2>
                        <p className="postedBy">Posted by: {this.state.username}</p>
                        <p className="postContent">{this.state.content}</p>

                        {
                            this.state.showComments ?
                                <div className="comments">
                                    <div className="hideComments" onClick={this.toggleComments}>Hide Comments</div>
                                    {
                                        this.state.comments.length === 0 ? <div className="noComments">No comments</div> :
                                            this.state.comments.map((comment) => (
                                                <Comment comment={comment} />
                                            ))
                                    }
                                    <div className="addComment" onClick={this.toggleModal}>Add Comment</div>

                                </div>
                                :
                                <div className="showComments" onClick={this.toggleComments}>
                                    Comments ({this.state.comments.length})
                                </div>
                        }
                        <AddCommentModal onClose={this.toggleModal} postId={this.state.postId} show={this.state.showCommentModal} />
                    </div>
                </div>
            </div>
        );
    }
}